*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto;
}

html {
  display: flex;
  flex-direction: column;
}

body {
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'aestetico-black';
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
.MuiDataGrid-footerContainer {
  background-color: white;
}

.truncate {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v30-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v30-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v30-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v30-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v30-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v30-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v30-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v30-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v30-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''), url('./assets/fonts/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/roboto-v30-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/roboto-v30-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/roboto-v30-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'aestetico-black';
  src: local('aestetico-black'), url('./assets/fonts/aestetico-black.otf') format('opentype');
}

@font-face {
  font-family: 'aestetico-bold';
  src: url('./assets/fonts/aestetico-bold.otf') format('opentype');
}

@font-face {
  font-family: 'aestetico-medium';
  src: url('./assets/fonts/aestetico-medium.otf') format('opentype');
}

@font-face {
  font-family: 'aestetico-semibold';
  src: url('./assets/fonts/aestetico-semibold.otf') format('opentype');
}
