.custom-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  min-height: 250px !important;
}

.custom-editor {
  min-height: 250px !important;
  max-height: 30vh !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
